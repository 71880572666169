import axios from "axios";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Nav,
  Navbar,
  Row,
  Table,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useUserStore } from "./store";

const Home = () => {
  const [vatNumbersData, setVatNumbersData] = useState([]);
  const [initValues, setInitValues] = useState({
    _id: "",
    name: "",
    vatNumber: "",
    isActive: true,
    isDeleted: false,
  });
  const [key, setKey] = useState(Math.random());
  const navigate = useNavigate();
  const userData = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);

  useEffect(() => {
    if (!userData.isAuth) {
      navigate("/login");
    }
  }, [navigate, userData.isAuth]);

  useEffect(() => {
    (async () => {
      await axios
        .get("https://getqr.amitaujas.com/api/vatNumber")
        .then((res) => res.data)
        .then((res) => setVatNumbersData(res.data))
        .catch((error) => {
          if (error.response) {
            toast.error(error.response.data.message);
          } else if (error.request) {
            toast.error(error.request);
          } else {
            toast.error(error.message);
          }
        });
    })();
  }, []);

  const getVatNumbersData = async () => {
    await axios
      .get("https://getqr.amitaujas.com/api/vatNumber")
      .then((res) => res.data)
      .then((res) => setVatNumbersData(res.data))
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        } else if (error.request) {
          toast.error(error.request);
        } else {
          toast.error(error.message);
        }
      });

    setKey(Math.random());
  };

  const handleSubmit = async (values) => {
    try {
      if (values._id) {
        await axios
          .post(
            `https://getqr.amitaujas.com/api/vatNumber/${values._id}`,
            values
          )
          .then((res) => res.data)
          .then((res) => {
            if (res.success) {
              toast.success(res.message || "");
              getVatNumbersData();
            } else {
              toast.error(res.message || "");
            }
          })
          .catch((error) => {
            if (error.response) {
              toast.error(error.response.data.message);
            } else if (error.request) {
              toast.error(error.request);
            } else {
              toast.error(error.message);
            }
          });
      } else {
        await axios
          .post("https://getqr.amitaujas.com/api/vatNumber", values)
          .then((res) => res.data)
          .then((res) => {
            if (res.success) {
              toast.success(res.message || "");
              getVatNumbersData();
            } else {
              toast.error(res.message || "");
            }
          })
          .catch((error) => {
            if (error.response) {
              toast.error(error.response.data.message);
            } else if (error.request) {
              toast.error(error.request);
            } else {
              toast.error(error.message);
            }
          });
      }

      setInitValues({
        _id: "",
        name: "",
        vatNumber: "",
        isActive: true,
        isDeleted: false,
      });
      setKey(Math.random());
    } catch (err) {
      toast.error("something went wrong");
    }
  };

  return (
    <div>
      <Toaster
        toastOptions={{
          success: {
            style: {
              background: "green",
              color: "white",
            },
          },
          error: {
            style: {
              background: "red",
              color: "black",
            },
          },
        }}
      />

      <Navbar bg="dark" data-bs-theme="dark" expand={"lg"}>
        <Container>
          <Navbar.Brand href="/">ZATCA QR</Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav>
              <Nav.Link
                onClick={() => {
                  setUser({ isAuth: false, jwt: "" });
                  navigate("/login");
                }}
              >
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container>
        <div style={{ margin: "1rem" }}>
          <Card>
            <Card.Header>
              <div>
                <h5>ADD VAT NUMBER</h5>
              </div>
            </Card.Header>

            <Card.Body>
              <div>
                <Formik
                  initialValues={initValues}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required("please enter name"),
                    vatNumber: Yup.number().required(
                      "please enter valid vat number"
                    ),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  key={key}
                  enableReinitialize
                >
                  {({
                    errors,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                  }) => {
                    return (
                      <Form onChange={handleChange} onSubmit={handleSubmit}>
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                NAME <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                              <Form.Control
                                value={values.name}
                                name="name"
                                type="text"
                                maxLength="100"
                                placeholder=""
                                isInvalid={!!touched.name && !!errors.name}
                                onChange={handleChange}
                              />
                              <Form.Text className="text-danger">
                                {errors.name || ""}
                              </Form.Text>
                            </Form.Group>
                          </Col>

                          <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                VAT NUMBER{" "}
                                <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                              <Form.Control
                                value={values.vatNumber}
                                name="vatNumber"
                                type="number"
                                maxLength="10"
                                placeholder=""
                                isInvalid={
                                  !!touched.vatNumber && !!errors.vatNumber
                                }
                                onChange={handleChange}
                              />
                              <Form.Text className="text-danger">
                                {errors.vatNumber || ""}
                              </Form.Text>
                            </Form.Group>
                          </Col>

                          <Col
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Check
                                type={"checkbox"}
                                label={`Is Active`}
                                name="isActive"
                                onChange={handleChange}
                                value={values.isActive}
                                defaultChecked={values.isActive}
                              />
                            </Form.Group>
                          </Col>

                          <Col>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button type="submit">
                                {values._id ? "UPDATE" : "ADD"}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </Card.Body>
          </Card>
        </div>

        <br />

        <div style={{ margin: "1rem" }}>
          <Card>
            <Card.Header>
              <div>
                <h5>VIEW VAT NUMBER</h5>
              </div>
            </Card.Header>

            <Card.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>SR. NO.</th>
                    <th>NAME</th>
                    <th>VAT NUMBER</th>
                    <th>IS ACTIVE</th>
                    <th>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {vatNumbersData.map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item?.name || ""}</td>
                      <td>{item?.vatNumber || ""}</td>
                      <td>{item.isActive ? "Yes" : "No"}</td>
                      <td>
                        <div style={{ display: "flex", gap: "1rem" }}>
                          <Button
                            onClick={() => {
                              setInitValues({
                                ...item,
                              });

                              setKey(Math.random());
                            }}
                          >
                            UPDATE
                          </Button>

                          <Button
                            variant="danger"
                            onClick={() => {
                              Swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, delete it!",
                              }).then(async (result) => {
                                if (result.isConfirmed) {
                                  await axios
                                    .delete(
                                      `https://getqr.amitaujas.com/api/vatNumber/${item._id}`
                                    )
                                    .then((res) => res.data)
                                    .then((res) => {
                                      if (res.success) {
                                        Swal.fire({
                                          title: "Deleted!",
                                          text: "VAT number has been deleted.",
                                          icon: "success",
                                        });

                                        getVatNumbersData();
                                      } else {
                                        toast.error(res.message || "");
                                      }
                                    })
                                    .catch((error) => {
                                      if (error.response) {
                                        toast.error(
                                          error.response.data.message
                                        );
                                      } else if (error.request) {
                                        toast.error(error.request);
                                      } else {
                                        toast.error(error.message);
                                      }
                                    });
                                }
                              });
                            }}
                          >
                            DELETE
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default Home;
