import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export const useUserStore = create(
  persist(
    (set) => ({
      user: { isAuth: false, jwt: "" },
      setUser: (payload) =>
        set((state) => ({
          user: { isAuth: payload.isAuth, jwt: payload.jwt },
        })),
    }),
    {
      name: "zatca-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
