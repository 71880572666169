import axios from "axios";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import logoImage from "./ZATCA_Logo.png";
import { useUserStore } from "./store";

const Login = () => {
  const navigate = useNavigate();
  const setUser = useUserStore((state) => state.setUser);
  const userData = useUserStore((state) => state.user);

  useEffect(() => {
    if (userData.isAuth) {
      navigate("/");
    }
  }, [navigate, userData.isAuth]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await axios
        .post("https://getqr.amitaujas.com/api/auth", values)
        .then((res) => res.data)
        .then((res) => {
          if (res.success) {
            toast.success(res.message || "");
            setUser({ isAuth: true, jwt: "" });
            navigate("/");
          } else {
            toast.error(res.message || "");
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error.response.data.message);
          } else if (error.request) {
            toast.error(error.request);
          } else {
            toast.error(error.message);
          }
        });
    } catch (err) {
      toast.error("something went wrong");
    }
  };

  return (
    <div>
      <Toaster
        toastOptions={{
          success: {
            style: {
              background: "green",
              color: "white",
            },
          },
          error: {
            style: {
              background: "red",
              color: "white",
            },
          },
        }}
      />

      <br />
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignSelf: "center",
          }}
        >
          <Card style={{ width: "30rem" }}>
            <Card.Header>
              <Card.Img
                style={{ padding: "2rem" }}
                src={logoImage}
                alt="Image not found"
              />
            </Card.Header>
            <Card.Body>
              <Card.Title
                style={{
                  textAlign: "center",
                }}
              >
                <h1>LOGIN</h1>
              </Card.Title>

              <br />

              <div>
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email("please enter valid email address")
                      .required("please enter email address"),
                    password: Yup.string().required(),
                  })}
                  onSubmit={handleSubmit}
                >
                  {({
                    errors,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  }) => {
                    return (
                      <Form onChange={handleChange} onSubmit={handleSubmit}>
                        <Row>
                          <Col>
                            <Form.Group className="mb-3">
                              <Form.Label>
                                EMAIL <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                              <Form.Control
                                value={values.email}
                                name="email"
                                type="email"
                                placeholder=""
                                isInvalid={!!touched.email && !!errors.email}
                                onChange={handleChange}
                              />
                              <Form.Text className="text-danger">
                                {errors.email || ""}
                              </Form.Text>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <Form.Group className="mb-3">
                              <Form.Label>
                                PASSWORD <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                              <Form.Control
                                value={values.password}
                                name="password"
                                type="password"
                                placeholder=""
                                isInvalid={
                                  !!touched.password && !!errors.password
                                }
                                onChange={handleChange}
                              />
                              <Form.Text className="text-danger">
                                {errors.password || ""}
                              </Form.Text>
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <Button
                              style={{ width: "100%" }}
                              type="submit"
                              disabled={isSubmitting}
                            >
                              SUBMIT
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default Login;
